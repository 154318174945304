/**
 * Created by fruysshaert on 20/06/2017.
 */
function SetValuetoCustomTextBox(p_clientId, p_sIMEI, p_frameId) {
    var l_currentField;
    if (!!p_frameId) {
        l_currentField = document.getElementById(p_frameId).contentWindow.document.getElementById(p_clientId);
    }
    else {
        l_currentField = document.getElementById(p_clientId);
    }

    if (!p_sIMEI || p_sIMEI === '\n') {
        l_currentField.value = '';

    }
    else {
        l_currentField.value = p_sIMEI;
    }
}
